<template>
  <div class="footer-inside">
    <div flex="1" class="box" direction="column" valign="center">
      <div class="box" gap="xs" pdl="xs" valign="center">
        <Logo
          :type="`symbol`"
          :width="`20`"
        />
        <component
          :is="SocialLink"
          :size="`small`"
        />
      </div>
      <div class="box" gap="md" pdt="sm" halign="center" valign="center" fsize="sm">
        <NuxtLink to="/our" :title="$t('aboutus')" color="md">{{ $t('aboutus') }}</NuxtLink>
        <span color="2xs" fsize="2xs">|</span>
        <NuxtLink to="/our/agreement" :title="$t('agreement')" color="md">{{ $t('agreement') }}</NuxtLink>
        <span color="2xs" fsize="2xs">|</span>
        <NuxtLink to="/our/privacy" :title="$t('privacy')" color="md">{{ $t('privacy') }}</NuxtLink>
        <span color="2xs" fsize="2xs">|</span>
        <component
          :is="LanguageMenu"
          :fsize="`sm`"
          :flag="18"
        />
      </div>
      <p pd="xs" />
      <div class="box" direction="column" gap="4xs" valign="center">
        <p fsize="xs">
          {{ $t('copyright-license') }}
          <span color="sm" pdr="3xs">
            <v-icon icon="mdi-slash-forward" fsize="xs" />
          </span>
          <span color="xl">{{ $t('copyright-email-id') }}@{{ $t('copyright-email-domain') }}</span>
        </p>
        <p fsize="2xs" color="md">
          {{ $t('copyright-ltd') }}
          {{ $t('copyright-allright') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// import LanguageMenu from '~/component/element/language-menu.vue'
// import SocialLink from '~/component/element/social-link.vue'

export default {
  setup () {

    // 메모리 비우기
    function restore () {
      LanguageMenu.value = null
      SocialLink.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const LanguageMenu: any = shallowRef(null)
    const SocialLink: any = shallowRef(null)
    const loadComponent = async () => {
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const SocialLink_load = await import('~/component/element/social-link.vue')
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      SocialLink.value = SocialLink_load.default || SocialLink_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      // 컴포넌트
      LanguageMenu,
      SocialLink
    }
  }
}
</script>